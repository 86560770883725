import React from 'react';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { ProductsGrid } from '@components/ProductsGrid';
import { ProductTile } from '@components/Tiles/ProductTile/ProductTile';
import { ProductBasicData } from '@api';
import { ProductTile as IProductTile } from '@commons/product';
import { DYNAMIC_FEATURES } from '@commons/dynamicFeatures';
import { isFeatureEnabled } from '@utils/dynamicFeatures';
import { GaValuesProps } from '@modules/ga/context/channel';
import styles from './OptionalAdditionsModal.module.scss';

export interface OptionalAdditionsProps {
  title: string;
  products: ProductBasicData[];
  isOpen: boolean;
  toggleClose: () => void;
  gaValues?: GaValuesProps;
}

export const OptionalAdditionsModal = ({
  title,
  products,
  isOpen,
  toggleClose,
  gaValues,
}: OptionalAdditionsProps) => {
  const handleClick = () => {
    if (isOpen) {
      toggleClose();
    }
  };
  const isV2 = isFeatureEnabled(DYNAMIC_FEATURES.NEW_PRODUCT_TILES);

  return (
    <ModalWindow
      title={title}
      className={styles.modal}
      open={isOpen}
      onClose={toggleClose}
      closeButtonSize={{
        height: 11.78,
        width: 11.78,
      }}
    >
      <ProductsGrid
        products={products as unknown as IProductTile[]}
        xs={6}
        sm={4}
        lg={4}
        gaValues={gaValues}
        customTileComponent={(product) => (
          <ProductTile product={product} onClick={handleClick} isV2={isV2} gaValues={gaValues} />
        )}
      />
    </ModalWindow>
  );
};
